


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwZUYTm1SAbPknZBqxVsvJALFG8oxNxhQ",
  authDomain: "creative-monkey-ecosystem.firebaseapp.com",
  projectId: "creative-monkey-ecosystem",
  storageBucket: "creative-monkey-ecosystem.appspot.com",
  messagingSenderId: "850348107694",
  appId: "1:850348107694:web:f2dd2fcdfa4bfbc21eca3f",
  measurementId: "G-FGRH777FGE"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const cloud = getFunctions(app)
const analytics = getAnalytics(app);
const auth = getAuth(app);



export { app, cloud, analytics, auth };
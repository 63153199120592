
import './app.scss';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import NoPage from './pages/nopage/NoPage';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { useEffect, useState } from 'react';
import { app, auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Auth, { UserContext } from './utility/Auth';
import Account from './pages/account/Account';
import { GlobalContext } from './utility/CTX';
import Software from './pages/software/Software';
import Network from './pages/network/Network';
import Marketplace from './pages/marketplace/Marketplace';







function App() {
  const [user, setUser] = useState(null);
  const [ctx, setCtx] = useState({
    page: 'Home'
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);




  return (
    <GlobalContext.Provider value={{ctx, setCtx}}>
      <UserContext.Provider value={{user, setUser}}>
        <BrowserRouter>

          <Header />

          <Routes>
            <Route path="/"             element={<Home />} />
            <Route path="/software"     element={<Software />} />
            <Route path="/marketplace"  element={<Marketplace />} />
            <Route path="/about"        element={<About />} />
            <Route path="/contact"      element={<Contact />} />
            <Route path="/nopage"       element={<NoPage />} />
            <Route path="/auth"         element={<Auth />} />
            <Route path="/account"      element={<Account />} />
            <Route path="/network"      element={<Network />} />
          </Routes>

          <Footer />

        </BrowserRouter>
      </UserContext.Provider>
    </GlobalContext.Provider>
  );
}

export default App;
